export const replaceAll = (str, search, replacement) => {
  return str.split(search).join(replacement);
};

export const zipmap = (keys, vals) => {
  if (keys.length !== vals.length) {
    throw Error('keys and vals must be the same length');
  }
  const obj = {};
  const n = keys.length;
  for (let i = 0; i < n; i++) {
    obj[keys[i]] = vals[i]
  }
  return obj;
};

export const transposeArray = (array) => {
  return array[0].map((col, i) => array.map(row => row[i]));
};

const capitalizeWord = w => w.charAt(0).toUpperCase() + w.slice(1);

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.split(' ').map(capitalizeWord).join(' ');
};

export const range = (i1, i2) => {
  /**
   * If i2 is undefined, 0, i1 are start, end, otherwise i1, i2 are start, end
   */
  function getRange() {
    if (typeof i2 !== 'undefined') {
        return [i1, i2];
    } else {
      return [0, i1];
    }
  }
  const [start, end] = getRange();
  const iter = Array.apply(null, Array(end - start));
  return iter.map((_, i) => start + i);
};
