export const FONT_SIZES = {
  desktop: {
    body: '14px',
    h1: '28px',
    h3: '15px'
  },
  tablet: {
    body: '14px',
    h1: '28px',
    h3: '15px'
  },
  phablet: {
    body: '14px',
    h1: '28px',
    h3: '15px'
  },
  mobile: {
    body: '14px',
    h1: '19px',
    h2: '17px',
    h3: '15px'
  }
}

export const getFontSize = (device, selector) => {
  return FONT_SIZES[device][selector] || 'default';
};
