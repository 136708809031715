import React from 'react';
import styled from 'styled-components';
import {Match} from "@reach/router";

const Container = styled.footer`
  text-align: center;
  margin: 30px 0 25px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  .main-footer {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    & > * {
      display: inline-block;
      margin: 0 5px;
      line-height: 2em;
    }
    .copyright {
      white-space: nowrap;
    }
  }
`;

const DisclosureComponent = ({className}) => (
  <div className={className}>
    This site participates in the Amazon Associate program
    and earns from qualifying purchases.
  </div>
);

const Disclosure = styled(DisclosureComponent)`
  margin-top: 10px;
`;

const MaybeDisclosure = ({path}) => (
  <Match path={path}>
    {props => props.match ? <Disclosure /> : null}
  </Match>
);

const Footer = () => (
  <Container>
    <div className="main-footer">
      <div className='copyright'>© 2020 Recipe Watch</div>
      <a href="/about">About</a>
      <a href="/privacy">Privacy</a>
      <a href="/categories">Categories</a>
    </div>
    <MaybeDisclosure path="/categories/*" />
    <MaybeDisclosure path="/recipes/*" />
  </Container>
);

export default Footer;


