module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-23637472-2","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/ken/Documents/Projects/recipewatch-site/src/components/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"recipewatch","short_name":"recipewatch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"/Users/ken/Documents/Projects/recipewatch-site/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a26cec68823f43cfb87d22ecbfb53036"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
