export const CARD_BG_COLOR = '#FDFDFD';
export const PAGE_BG_COLOR_DESKTOP = '#F8F9FA';
export const PAGE_BG_COLOR_MOBILE = PAGE_BG_COLOR_DESKTOP;
export const MOBILE_RECIPE_NAV_BG_COLOR = '#F2F3F4'
export const MOBILE_RECIPE_NAV_BORDER_COLOR = '#E0E1E2';
export const PAGE_FG_COLOR = '#555';
export const NUTRITION_FACTS_ROW_BORDER_COLOR = '#999';
export const BORDER_COLOR = '#DDD';
export const SECTION_CHEVRON_COLOR = '#AAA';
export const SECTION_HEADER_COLOR_MOBILE = '#666';
export const BLOCK_TEXT_COLOR = '#444';
export const MAIN_BLUE = '#08e';
export const LINK_COLOR = MAIN_BLUE;
export const LINK_VISITED_COLOR = '#85e';
export const CONTENTS_BORDER_COLOR = '#EEE';
export const HEADER_COLOR_CONTENTS = '#cee9ff';
export const HEADER_COLOR_INGREDIENT_COMPARISON = '#cee9ff';
export const TOPIC_THUMB_HIGHLIGHT_COLOR = '#cee9ff';
export const TOPIC_THUMB_HOVER_COLOR = '#e6f4ff';
export const HEADER_COLOR_VIDEO = '#FDD';
export const HEADER_COLOR_INGREDIENTS = '#DFD';
export const HEADER_COLOR_NUTRITION_FACTS = '#F0F0F0';
export const HEADER_COLOR_SUMMARY = '#cee9ff';
export const HEADER_COLOR_MACRONUTRIENTS = '#FFB';

export const OVERVIEW_PLOT_COLORS = [
  '#BBB',
  MAIN_BLUE,
  '#E36'
];
