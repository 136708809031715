import React from 'react';
import PropTypes from 'prop-types';
import styled, {createGlobalStyle} from "styled-components";
import {
  BREAKPOINTS,
  fontSize,
  MAX_DESKTOP_WIDTH, MAX_DESKTOP_WIDTH_MINUS_PADDING, NAV_HEIGHT, PAGE_PADDING,
  responsive
} from "../styles/dimens";
import {
  BLOCK_TEXT_COLOR, LINK_COLOR, LINK_VISITED_COLOR, MAIN_BLUE,
  PAGE_BG_COLOR_DESKTOP, PAGE_BG_COLOR_MOBILE,
  PAGE_FG_COLOR
} from "../styles/colors";
import Footer from "./Footer";
import { fade, createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Visibility';  // Theaters
import TagsIcon from '@material-ui/icons/LocalDining';  // Category, LocalOffer
import MoreIcon from '@material-ui/icons/MoreVert';
import {Link} from "gatsby";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    ${fontSize('body')};
    color: ${PAGE_FG_COLOR};
    ${responsive({
      'phablet-desktop': `
          background-color: ${PAGE_BG_COLOR_DESKTOP};
      `,
      'mobile': `
          background-color: ${PAGE_BG_COLOR_MOBILE};
      `
    })};
  }
  
  .wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  svg, svg * {
    -webkit-tap-highlight-color: transparent;
  }
  
  h1 {
    ${fontSize('h1')};
  }
  
  h2 {
    ${fontSize('h2')};
  }
  
  h3 {
    ${fontSize('h3')};
  }
  
  
  p, .block-text {
    color: ${BLOCK_TEXT_COLOR};
    line-height: 1.45rem;
  }
  
  a {
    color: ${LINK_COLOR}
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:visited {
    color: ${LINK_VISITED_COLOR};
  }
  
  .MuiAppBar-root {
    display: inline-block;
    a  {
      color: inherit;
    }
  }
  
  .MuiToolbar-root {
    max-width: ${MAX_DESKTOP_WIDTH_MINUS_PADDING}px;
    margin: auto;
  }

  .MuiToolbar-gutters {
    ${responsive({
      mobile: `
        padding: 0 14px 0 24px;
      `,
      'phablet-desktop': `
        padding: 0 24px 0 34px;
      `
    })};
  }
  
  .MuiListItem-root a {
      white-space: nowrap;
      width: 100%;
      display: flex;
      color: inherit;
      padding-right: 15px;
      
      &:hover {
        text-decoration: none;
      }
      
      p {
        line-height: 1.5rem;
      }
  }
  
  @media screen and (max-width: ${BREAKPOINTS.phablet - 1}px) {
    .mobile, .mobile-phablet, .mobile-tablet {
      display: block;
    }
    .phablet, .tablet, .desktop, .phablet-tablet, .tablet-desktop, .phablet-desktop {
      display: none;
    }
  }
  
  @media screen and (min-width: ${BREAKPOINTS.phablet}px) and 
                    (max-width: ${BREAKPOINTS.tablet - 1}px){
    .phablet, .mobile-tablet, .mobile-phablet, .phablet-tablet, .phablet-desktop {
      display: block;
    }
    
    .mobile, .tablet, .desktop, .tablet-desktop {
      display: none;
    }
  }
  
  @media screen and (min-width: ${BREAKPOINTS.tablet}px) and 
                    (max-width: ${BREAKPOINTS.desktop - 1}px){
    .tablet, .mobile-tablet, .phablet-tablet, .tablet-desktop, .phablet-desktop {
      display: block;
    }
    
    .mobile, .desktop {
      display: none;
    }
  }
  
  @media screen and (min-width: ${BREAKPOINTS.desktop}px) {
    .desktop, .tablet-desktop, .phablet-desktop {
      display: block;
    }
    
    .mobile, .tablet, .mobile-tablet {
      display: none;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 auto;
  ${responsive({
    'phablet-desktop': `
      max-width: ${MAX_DESKTOP_WIDTH}px;
      margin: auto;
    `
  })};
`;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    letterSpacing: '0.05em'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // search: {
  //   position: 'relative',
  //   flexGrow: 1,
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: fade(theme.palette.common.white, 0.15),
  //   '&:hover': {
  //     backgroundColor: fade(theme.palette.common.white, 0.25),
  //   },
  //   marginRight: theme.spacing(2),
  //   width: '100%',
  //   marginLeft: theme.spacing(3),
  //   [theme.breakpoints.up('md')]: {
  //     width: 'auto',
  //   },
  // },
  // searchIcon: {
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // inputRoot: {
  //   color: 'inherit',
  // },
  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.up('md')]: {
  //     width: '60ch',
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     width: '30ch',
  //   }
  // }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: MAIN_BLUE,
    }
  },
});

const Title = React.memo(styled(Link)`
    display: block;
    float: left;
    word-break: keep-all;
    font-size: 24px;
    line-height: ${NAV_HEIGHT}px;
    font-family: 'Dancing Script', cursive;
    font-weight: 550;
    flex-grow: 1;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
    
    & > * {
      float: left;
    }

    &:visited {
        color: inherit;
    }
    
    &:hover {
        text-decoration: none;
    }
`);

const TemplateWrapper = ({children}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to='/categories'>
          <IconButton aria-label="Categories" color="inherit">
            <TagsIcon />
          </IconButton>
          <p>Categories</p>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to='/about'>
          <IconButton aria-label="About" title="About" color="inherit">
            <InfoIcon />
          </IconButton>
          <p>About</p>
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className='wrapper'>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.sectionDesktop}>
              <Typography className={classes.title} variant="h6" noWrap>
                <Title to='/'>
                  {/*<img src={logo} alt='Recipe Watch' className='logo' />*/}
                  <div>Recipe Watch</div>
                </Title>
              </Typography>
            </div>
            <div className={classes.sectionMobile}>
              <Typography className={classes.title} variant="h6" noWrap>
                <Title to='/'>
                  {/*<img src={logo} alt='Recipe Watch' className='logo' />*/}
                  <div>RW</div>
                </Title>
              </Typography>
            </div>

            <div className={classes.grow} />

            {/*<div className={classes.search}>*/}
            {/*  <div className={classes.searchIcon}>*/}
            {/*    <SearchIcon />*/}
            {/*  </div>*/}
            {/*  <InputBase*/}
            {/*    placeholder="Search…"*/}
            {/*    classes={{*/}
            {/*      root: classes.inputRoot,*/}
            {/*      input: classes.inputInput,*/}
            {/*    }}*/}
            {/*    inputProps={{ 'aria-label': 'search' }}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className={classes.sectionDesktop}>
              <Link to='/categories'>
                <IconButton aria-label="Categories" title="Categories" color="inherit">
                  <TagsIcon />
                </IconButton>
              </Link>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        <Content>
          {children}
        </Content>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default TemplateWrapper;