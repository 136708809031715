import {css} from 'styled-components';
import { getFontSize } from "./fonts"
import {zipmap} from "../utils/misc";

export const SIDE_BAR_WIDTH = '180px';
export const SIDE_MENU_WIDTH = '160px';
export const MAX_MAIN_WIDTH = '630px';
export const OVERVIEW_PLOTS_OUTER_WIDTH = 250;
export const OVERVIEW_PLOTS_INNER_WIDTH = 200;
export const OVERVIEW_PLOTS_HEIGHT = 320;
export const MAX_DESKTOP_WIDTH = 960;

export const PAGE_PADDING_INT = 20;
export const PAGE_PADDING = PAGE_PADDING_INT + 'px';
export const MAX_DESKTOP_WIDTH_MINUS_PADDING = MAX_DESKTOP_WIDTH - PAGE_PADDING_INT * 2 - 20;
export const MACRONUTRIENTS_SIZE = 240;
export const NAV_HEIGHT = 50;
export const DESKTOP_LEFT_SECTION_WIDTH = '350px';
export const DESKTOP_RIGHT_SECTION_WIDTH = '240px';
export const DESKTOP_VIDEO_HEIGHT = '207px';
export const MOBILE_VIDEO_WIDTH = '280px';
export const MOBILE_VIDEO_HEIGHT = '158px';
export const BORDER_RADIUS = 3;
export const ICON_SIZE = 24;
export const THUMB_SIZE = 48;

export const BREAKPOINTS = {
  mobile: 0,
  phablet: 700,
  tablet: 840,
  desktop: 1140
};

export function responsive(devices) {
  return css`
  @media screen and (max-width: ${BREAKPOINTS.phablet - 1}px) {
    ${devices.mobile};
    ${devices['mobile-tablet']};
    ${devices['mobile-phablet']};
  }
  
    @media screen and (min-width: ${BREAKPOINTS.phablet}px) and 
                       (max-width: ${BREAKPOINTS.tablet - 1}px){
    ${devices.phablet};
    ${devices['mobile-tablet']};
    ${devices['mobile-phablet']};
    ${devices['phablet-tablet']};
    ${devices['phablet-desktop']};
  }
    
  @media screen and (min-width: ${BREAKPOINTS.tablet}px) and 
                    (max-width: ${BREAKPOINTS.desktop - 1}px){
    ${devices.tablet};
    ${devices['mobile-tablet']};
    ${devices['phablet-tablet']};
    ${devices['phablet-desktop']};
    ${devices['tablet-desktop']};
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}px) {
    ${devices.desktop};
    ${devices['phablet-desktop']};
    ${devices['tablet-desktop']};
  }`;
}

export function fontSize(selector) {
  return responsive(zipmap(
    Object.keys(BREAKPOINTS),
    Object.keys(BREAKPOINTS).map(
      device =>  `font-size: ${getFontSize(device, selector)}`)));
}
